/**
 * Product SEO Component
 */

import React from "react";
import Helmet from "react-helmet";
import { PageProps } from "gatsby";

type PropsType = {
  productName: string,
  productSmallDescription: string,
  productCategory: string,
  productPrice: number,
  productImage: string,
  productSlug: string,
  productCode: string,
  productBrand: string
}

const SEOProduct: React.FC<PropsType> = (data) =>{
  const {
    productName,
    productSmallDescription,
    productCategory,
    productPrice,
    productImage,
    productSlug,
    productCode,
    productBrand,
  } = data;


  const brandJsonLd = productBrand ? `
          ,"brand": {
            "@type": "Brand",
            "name": "${productBrand}"
          }`: "";

  const priceJsonLd = productPrice ? `
          ,"offers": {
            "@type": "Offer",
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition",
            "price": "${productPrice}",
            "priceCurrency": "HNL"
          }`: "";

  const aggregateRating = `,"aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "reviewCount": "48"
  }`

  const productUrl = `${process.env.MEDITEC_HOST}/producto/${productSlug}`;
  const productImageUrl = `${process.env.MEDITEC_HOST}${productImage}`;

  const priceMetadata = [];
  if(productPrice){
    priceMetadata.push(
      {
        property: `product:price.amount`,
        content: productPrice.toString(),
      },
      {
        property: `product:price.currency`,
        content: 'HNL',
      },
    );
  }

  return (

  <Helmet
    htmlAttributes={{
      lang: 'es-HN',
    }}
    title={productName}
    titleTemplate={`%s | ${productCategory} | Meditec Honduras`}
    meta={[
      {
        name: `description`,
        content: productSmallDescription,
      },
      {
        property: `og:title`,
        content: productName,
      },
      {
        property: `og:description`,
        content: productSmallDescription,
      },
      {
        property: `og:url`,
        content: productUrl,
      },
      {
        property: `og:updated_time`,
        content: new Date().getTime().toString()
      },
      {
        property: `og:type`,
        content: `product`,
      },
      {
        property: `og:image`,
        content: productImageUrl,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: 'meditec_hn',
      },
      {
        name: `twitter:title`,
        content: productName,
      },
      {
        name: `twitter:description`,
        content: productSmallDescription,
      },
      {
        name: `twitter:image`,
        content: productImageUrl,
      },
    ].concat(priceMetadata)}
  >
    <script type="application/ld+json">
    {`
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "url": "${productUrl}",
          "name": "${productName}",
          "image": ["${productImageUrl}"],
          "description": "${productSmallDescription}",
          "sku": "${productCode}"${brandJsonLd}${priceJsonLd}${aggregateRating}
        }
      `}
  </script>
  </Helmet>
)}

export default SEOProduct;
