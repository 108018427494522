import { ThemeUIStyleObject } from "theme-ui";

const styles:{
	wrapper: ThemeUIStyleObject,
	product: ThemeUIStyleObject,
	image: ThemeUIStyleObject,
	content: ThemeUIStyleObject,
	header: ThemeUIStyleObject,
	price: ThemeUIStyleObject,
	blurredPrice: ThemeUIStyleObject,
	cartArea: ThemeUIStyleObject,
	cartCounter: ThemeUIStyleObject,
	tagArea: ThemeUIStyleObject,
	itemCode: ThemeUIStyleObject,
	tagTitle: ThemeUIStyleObject,
	itemBrand: ThemeUIStyleObject,
	itemHeader: ThemeUIStyleObject,
	priceTextHolder: ThemeUIStyleObject,
	itemUom: ThemeUIStyleObject,
	itemAboutHolder: ThemeUIStyleObject,
	buyArea: ThemeUIStyleObject,
	shippingContainer: ThemeUIStyleObject,
	productAvailableToBuy: ThemeUIStyleObject,
	soldOut: ThemeUIStyleObject,
	hideinMobile: ThemeUIStyleObject,
	buyInformation: ThemeUIStyleObject,
	businessBuyInformation: ThemeUIStyleObject,
	productInformationWrapper: ThemeUIStyleObject,
	leftInfoWrapper: ThemeUIStyleObject,
	rightInfoWrapper: ThemeUIStyleObject,
	productInformationText: ThemeUIStyleObject,
	headingProductInformation: ThemeUIStyleObject
} = {
	wrapper: {
		'#relatedProducts': {
			paddingLeft: 0,
			paddingRight: 0,
			marginTop: '20px',
		},
	},
	product: {
		'@media only screen and (max-width: 768px)': {
			flexDirection: 'column',
		},
	},
	image: {
		width: '40%',
		borderRadius: '4px',
		borderRight: `1px solid #f0f0f0`,
		overflow: 'hidden',
		'@media only screen and (max-width: 1280px)': {
			width: '50%',
		},
		'@media only screen and (max-width: 768px)': {
			width: '100%',
		},
		'&.has-items': {
			width: '50%',
			display: 'flex',
			flexWrap: 'wrap',
			border: `0`,
			'@media only screen and (max-width: 1280px)': {
				width: '60%',
			},
			'@media only screen and (max-width: 768px)': {
				width: '100%',
			},
			'.gatsby-image-wrapper': {
				margin: '5px',
				width: 'calc(50% - 10px)',
				borderRadius: '4px',
				border: `1px solid #f0f0f0`,
			},
		},
	},
	content: {
		width: '40%',
		padding: ['0 5px','0 10px','0 15px','0 25px'],
		'@media only screen and (max-width: 1280px)': {
			width: '50%',
		},
		'@media only screen and (max-width: 768px)': {
			width: '100%',
			paddingLeft: '0',
			paddingTop: '30px',
		},
		h1: {
			color: 'primary',
			fontSize: '22px',
			lineHeight: '1.4',
			fontWeight: 600,
			'@media only screen and (max-width: 480px)': {
				fontSize: '20px',
			},
		},
		p: {
			fontSize: ['14px', '15px'],
			lineHeight: '1.86',
		},
	},
	buyArea:{
		overflow: 'hidden',
		padding: '10px 15px 20px 15px',
		border: `1px solid #d2d2d2`,
		height: 'fit-content',
		'@media only screen and (max-width: 1280px)': {
			width: '30%',
		},
		'@media only screen and (max-width: 768px)': {
			width: '100%',
			marginTop: '30px',
		},
		borderRadius: '4px',
	},
	businessBuyInformation:{
		overflow: 'hidden',
		padding: '20px 15px 25px 17px',
		border: `1px solid #d2d2d2`,
		height: 'fit-content',
		borderRadius: '4px',

		button: {
			width:'100%',
			fontSize: ['16px', '19px'],
			color: 'green',
			padding: '10px 0'
		}
	},
	shippingContainer:{
		table:{
			borderSpacing: '0',
			paddingBottom: "15px",
			paddingTop: "15px",
			tr:{
				'td:nth-child(1)':{
					fontSize: '13px',
					paddingRight: '10px'
				},
				'td:nth-child(2)':{
					fontWeight:'600'
				}
			}
		}
	},
	hideinMobile:{
		'@media only screen and (max-width: 768px)': {
			display:'none'
		},
	},
	header: {
		borderBottom: `1px solid #d2d2d2`,
		paddingBottom: '15px',
		marginBottom: '15px',
	},
	itemCode:{
		fontSize: '12px'
	},
	itemHeader:{
		borderBottom: `1px solid #d2d2d2`,
		paddingBottom: ['5px', '8px'],
		marginBottom: ['5px', '8px'],
	},
	itemBrand:{
		
	},
	priceTextHolder:{
		float: "left",
		marginTop: "0.4em",
		marginRight: "5px",
		fontSize: '13.5px',
	},
	itemUom:{
		fontWeight: 600,
	},
	itemAboutHolder:{
		fontWeight: 600,
	},
	price: {
		color: 'price',
		fontSize: '22px',
		fontWeight: 600,
		lineHeight: '1.538',
		'@media only screen and (max-width: 480px)': {
			fontSize: '24px',
		},
	},
	blurredPrice:{
		textShadow: '0 0 12px #B12704',
		color: 'transparent',
		userSelect: 'none'
	},
	cartArea: {
		alignItems: 'center',
		justifyContent: 'space-between',
		'.addToCart': {
			minWidth: '120px',
			fontSize: '15px',
			fontWeight: 600,
		},
		'@media only screen and (max-width: 768px)': {
			display:'none'
		},
	},
	productAvailableToBuy: {
		color: 'green',
		paddingBottom: "20px",
		fontWeight:'600'
	},
	cartCounter: {
		display: 'flex',
		alignItems: 'center',
		minWidth: [130, 172],
		minHeight: [40, 50],
		borderRadius: 6,
		overflow: 'hidden',
		backgroundColor: 'medical',
		transition: 'all 0.2s ease',
		div: {
			width: [50, 72],
			color: 'white',
			textAlign: 'center',
			fontSize: 0,
			fontWeight: '700',
			paddingLeft: 0,
			paddingRight: 0,
		},
		button: {
			flexGrow: 1,
			padding: 0,
			minWidth: [40, 50],
			minHeight: [40, 50],
			svg: {
				width: 24,
				height: 24,
				marginLeft: 0,
			},
			backgroundColor: 'medical'
		},
	},
	tagArea: {
		margin: ['30px 0', '40px 0'],
		color: 'primary',
		display: 'grid',
		gridTemplateColumns: 'auto 1fr',
		span: {
			display: 'inline-block',
			color: '#919191',
			margin: '2.5px',
			padding: '4px 12px',
			borderRadius: '6px',
			fontSize: '13px',
			fontWeight: 600,
			border: `1px solid #DBDBDB`,
		},
	},
	tagTitle: {
		fontSize: ['14px', '15px'],
		marginRight: '12px',
		marginTop: '5px',
	},
	soldOut:{

	},
	buyInformation:{
		flexDirection: "column",
		width: '20%',
		'@media only screen and (max-width: 1280px)': {
			width: '30%',
		},
		'@media only screen and (max-width: 768px)': {
			width: '100%',
			marginTop: '30px',
		},
	},
	productInformationWrapper:{
		marginTop: '1em',
		paddingTop: '1em',
		borderTop: '1px solid #e7e7e7',
		table:{
			color: 'black',
			width: '100%',
			borderBottom: '1px solid #e7e7e7',
			marginBottom: '1em',
			borderSpacing: 'inherit',
			tr:{
				th:{
					width: '50%',
					textAlign: 'left',
					fontWeight: 'initial',
					wordBreak: 'break-word',
					padding: '7px 14px 6px',
					borderTop: '1px solid #e7e7e7',
					backgroundColor: '#f3f3f3',
				},
				td:{
					width: '50%',
					textAlign: 'left',
					wordBreak: 'break-word',
					padding: '7px 14px 6px',
					borderTop: '1px solid #e7e7e7',
				}
			}
		}
	},
	leftInfoWrapper:{
		width: '50%',
		paddingRight: '1em'
	},
	rightInfoWrapper:{
		width: '50%',
		paddingLeft: '1em'
	},
	productInformationText:{
		color: '#B12704',
		paddingBottom: '1em'
	},
	headingProductInformation: {
		paddingBottom: '0.5em',
		borderBottom: '1px solid #e7e7e7',
		marginBottom: '1em',
	}
};

export default styles;
