/** @jsx jsx */
import React, {
	useRef,
	useState,
	useEffect,
	useContext,
	useCallback,
} from 'react';
import { Flex, Box, Heading, Text, jsx, Button } from 'theme-ui';
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import PrimaryLayout from '../components/layout/primary/primary';
import SocialShare from '../components/social-share/social-share';
import CallusBanner from '../components/call-us-banner/call-us-banner';
import { LocalCartContext } from '../provider/local-cart-provider';
import useOnClickOutside from '../hooks/useOutsideClick';
import styles from '../components/product-single.style';
import ProductGrid from '../components/product-grid/product-grid';
import SEOProduct from '../components/seo-product';
import { ModalContext } from '../provider/modal-provider';
import ReactWhatsapp from 'react-whatsapp';
import {
	FaWhatsappSquare
} from 'react-icons/fa';

const ProductPage: React.FC<any> = ({
	data: { erpItem, erpCategory, prismicHomeminimal },
	location: { href },
}) => {
	let product = erpItem;
	product.listView = false;

	const price = {
		currencyCode: 'HNL',
		amount: product.last_purchase_rate
	};
	const categoryProducts:any[] = [];
	erpCategory?.childrenErpItem?.forEach((categoryProduct:any) => {
		if(categoryProducts.length < 6 && (categoryProduct.item_code != erpItem.item_code)){
			categoryProducts.push(categoryProduct);
		}
	});

	const { products, add, update } = useContext(LocalCartContext);
	const { setIsBusinessAccountAdOpen } = useContext(ModalContext);

	const counterRef = useRef(null!);
	const [showCounter, setShowCounter] = useState(false);
	useOnClickOutside(counterRef, () => setShowCounter(false));

	const productIndex = products.findIndex(
		(item) => item.variantId === product.item_code
	);

	const cartProduct = productIndex > -1 ? products[productIndex] : false;
	const cartProductQuantity = cartProduct ? cartProduct.quantity : 0;
	const [quantity, setQuantity] = useState(0);
	const [available, setAvailable] = useState(product.is_sales_item);
	
	useEffect(() => {
		setQuantity(cartProductQuantity);
	}, [cartProductQuantity]);

	const handleQuantityChange = (quantity: number) => {
		update(product.item_code, quantity);
		if (quantity < 1) {
			setShowCounter(false);
		}
	};

	const handleAddToCart = () => {
		if (!available) {
			return false;
		}
		setShowCounter(true);
		if (quantity < 1) {
			const item = {
				title: product.item_name,
				thumbnail: product?.localImage?.childImageSharp,
				quantity: 1,
				price: product.last_purchase_rate,
				currency: 'HNL',
				variantId: product.item_code,
			};
			add(item);
		}
	};

	const addClass = ['productCard'];
	if (quantity > 0 && showCounter) {
		addClass.push('active');
	}
	if (!available) {
		addClass.push('disabled');
	}

	const callUsBannerData = prismicHomeminimal?.data;
	const getPrice = (price: any) =>
		Intl.NumberFormat('es-HN', {
			currency: price.currencyCode,
			minimumFractionDigits: 2,
			style: 'currency',
		}).format(parseFloat(price && price.amount ? price.amount : 0));

	return (
		<PrimaryLayout
			bgColor="#ffffff"
			fluid={true}
			showNoticeBar={false}
		>
			<Box sx={styles.wrapper}>
				<SEOProduct 
					productName={product?.item_name}
					productSmallDescription={`${product?.item_name} de venta en Honduras. ${product?.description_text}`}
					productCategory={product?.item_group}
					productPrice={price.amount}
					productImage={product?.localImage?.childImageSharp.gatsbyImageData.images.fallback.src}
					productSlug={product?.slug}
					productCode={product?.item_code}
					productBrand={product?.brand}
				/>
				<Flex sx={styles.product}>
					<Box
						className={''}
						sx={styles.image}
					>
						{product?.localImage && (
							<GatsbyImage
								image={product?.localImage?.childImageSharp.gatsbyImageData}
								alt={product?.item_name}
							/>
						)}
					</Box>
					<Box sx={styles.content}>
						<Box sx={styles.itemHeader}>
							<Text sx={styles.itemCode}>SKU: {product.item_code}</Text>
							<Heading as="h1">{product.item_name}</Heading>
							<Text sx={styles.itemBrand}>Marca: {product.brand}</Text>
						</Box>
						<Box sx={styles.header}>
							{/* <Flex sx={styles.cartArea}>
								
								{(product.last_purchase_rate && (process.env.GO_LIVE == 'true')) && (
									<Text><Text sx={styles.priceTextHolder}>Precio:</Text><Text sx={styles.price}>{getPrice(price)}</Text></Text>
								)}
							</Flex> */}
							
							{process.env.GO_LIVE == 'true' && (
								<>
									{/* <Text as="p"><Text sx={styles.priceTextHolder}>Precio:</Text><Text sx={{...styles.price, ...styles.blurredPrice}}>L 99.99</Text></Text> */}
									<Text as="p" sx={{color: 'price', fontWeight:'600', paddingBottom: '10px'}}>
										<svg xmlns="http://www.w3.org/2000/svg" style={{paddingRight:'5px'}} width="15" height="15" viewBox="0 0 24 24">
											<path 
												fill="#B12704"
												d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"
											/>
										</svg>
										 Para ver precios, te invitamos a crear una cuenta de negocios o inicia sesión
									</Text>
								</>
							)}
							<Text as="p" sx={{marginTop:'5px'}}>
								Unidad de venta: <Text sx={styles.itemUom}>{product.stock_uom}</Text>
							</Text>
						</Box>
						<Box sx={styles.itemHeader}>
							<Text sx={styles.itemAboutHolder}>Acerca de este producto:</Text>
							<Text as="p" dangerouslySetInnerHTML={{__html: product?.description}} />
						</Box>
						{product?.tags && (
							<Box sx={styles.tagArea}>
								<Text sx={styles.tagTitle}>Tag:</Text>
								<Box>
									{product?.tags.map((item: string) => (
										<Box key={item} as="span">
											{item}
										</Box>
									))}
								</Box>
							</Box>
						)}
						<SocialShare title={product?.title} url={href} />
					</Box>
					<Flex sx={styles.buyInformation}>
						{
							(process.env.GO_LIVE == 'true') && (
								<Box sx={styles.businessBuyInformation}>
									<Text as="h3" sx={{paddingBottom: '5px', fontWeight: '600'}}>Empresas</Text>
									<Text as="p">Consulte precios y disponibilidad con una cuenta de negocios</Text>
		
									<Button
										sx={{width: '100%', backgroundColor: 'medical', marginTop: '20px'}}
									>
										Iniciar Sesión
									</Button>
									<Button
										sx={{width: '100%', backgroundColor: 'green_medical', marginTop: '10px'}}
										onClick={ () => { setIsBusinessAccountAdOpen(true); }}
									>
										Más Información
									</Button>
								</Box>
							)
						}
						<Box sx={styles.businessBuyInformation}>
							<ReactWhatsapp number="+504-3178-7085" message={`Hola, deseo conocer precio y disponibilidad del producto: *${product.item_name} - ${product.item_code}*`}>
								<Text as="p" sx={{fontSize:'40px', lineHeight:'10px'}}><FaWhatsappSquare /> </Text>
								Preguntar Via Whatsapp
							</ReactWhatsapp>
						</Box>

						{/* <Box sx={styles.buyArea}>
								{product.last_purchase_rate && (
									<Text><Text sx={styles.priceTextHolder}>Precio:</Text><Text sx={styles.price}>{getPrice(price)}</Text></Text>
								)}
								<Box sx={styles.shippingContainer}>
									<table>
										<tr>
											<td>Envio Valle de Sula:</td>
											<td>1 a 2 días</td>
										</tr>
										<tr>
											<td>Envio Nacional:</td>
											<td>3 a 5 días</td>
										</tr>
									</table>
								</Box>

								{!product.disabled ? (
									<Box sx={styles.productAvailableToBuy}>
										Disponible
									</Box>
								) : (
									<Box sx={styles.soldOut}>
										No disponible por el momento
									</Box>
								)}

								<Box
									className={quantity > 0 && showCounter ? 'isActive' : ''}
									
								>
									{!quantity && (
										<Button
											className={addClass.join(' ')}
											onClick={() => handleAddToCart()}
											sx={{width: '100%', backgroundColor: 'medical'}}
										>
											Agregar a Cotización
										</Button>
									)}
									{quantity ? (
										<Box ref={counterRef} sx={styles.cartCounter}>
											<Button
												title="Decrement"
												onClick={() => handleQuantityChange(quantity - 1)}
											>
												<IoIosRemove />
											</Button>
											<Box>{quantity}</Box>
											<Button
												title="Increment"
												onClick={() => handleQuantityChange(quantity + 1)}
											>
												<IoIosAdd />
											</Button>
										</Box>
									) : null}
								</Box>
						</Box> */}
					
					</Flex>
				</Flex>
				<Box sx={styles.productInformationWrapper}>
					<Heading as="h3" sx={styles.productInformationText}>Información del Producto</Heading>
					<Flex>
						<Box sx={styles.leftInfoWrapper}>
							<Heading as="h4" sx={styles.headingProductInformation}>Especificaciones Técnicas</Heading>
							<table>
								<tbody>
								{product.website_specifications.map((specification: any) => {
									return (
										<tr>
											<th>{specification.label}</th>
											<td>{specification.description}</td>
										</tr>
									)
								})}
								</tbody>
							</table>
						</Box>
						<Box sx={styles.rightInfoWrapper}>
							<Heading as="h4" sx={styles.headingProductInformation}>Información Adicional</Heading>
							<table>
								<tbody>
									<tr>
										<th>SKU</th>
										<td>{product.item_code}</td>
									</tr>
									<tr>
										<th>Marca</th>
										<td>{product.brand}</td>
									</tr>
								</tbody>
							</table>
							<Heading as="h4" sx={styles.headingProductInformation}>Unidades de venta</Heading>
							<table>
								<tbody>
								{product.uoms.map((uom: any) => {
									return (
										<tr>
											<th>{uom.uom}</th>
											<td>{uom.conversion_factor} {product.stock_uom}</td>
										</tr>
									)
								})}
								</tbody>
							</table>
						</Box>
					</Flex>
						
				</Box>
				<ProductGrid
					id="relatedProducts"
					gridTitle="Productos Relacionados"
					products={categoryProducts}
					withLink={true}
					isCategoryProduct={true}
				/>
				<CallusBanner
					scrollTo="#relatedProducts"
					callUsBanner={callUsBannerData?.call_us_banner}
					callUsTitle={callUsBannerData?.call_us_banner_content?.raw}
					callUsButtonText={callUsBannerData?.call_us_button_text?.raw}
				/>
			</Box>
		</PrimaryLayout>
	);
};

export const pageQuery = graphql`
	query($slug: String!) {
		erpItem(slug: { eq: $slug }) {
			item_name
			description
			creation
			item_code
			is_sales_item
			last_purchase_rate
			stock_uom
			item_group
			brand
			description_text
			slug
			disabled
			uoms {
				conversion_factor
				uom
			}
			website_specifications {
				label
				description
			}
			localImage {
				childImageSharp {
					gatsbyImageData(
						aspectRatio: 1.25,
						placeholder: TRACED_SVG,
						transformOptions: {fit: CONTAIN},
						backgroundColor: "transparent"
					)
				}
			}
		}
		erpCategory(childrenErpItem: { elemMatch: { slug: { eq: $slug } } }) {
			childrenErpItem {
				id
				item_name
				slug
				creation
				item_code
				is_sales_item
				last_purchase_rate
				localImage {
					childImageSharp {
						gatsbyImageData(
							aspectRatio: 1.25,
							placeholder: TRACED_SVG,
							transformOptions: {fit: CONTAIN},
							backgroundColor: "transparent"
						)
					}
				}
			}
		}
		prismicHomeminimal {
			data {
				call_us_banner {
					url
				}
				call_us_banner_content {
					raw
				}
				call_us_button_text {
					raw
				}
			}
		}
	}
`;

export default ProductPage;
